@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");
html {
  width: 100% !important;
  overflow-x: hidden !important;
}
body {
  font-family: "poppins", "Almarai" !important;
  overflow-x: hidden !important;
}
.cl-text {
  color: rgb(221, 70, 0);
  font-weight: 500;
}
/*maximum width for the website */

/* styling the navbar and the header */
.cont1 {
  /* background: linear-gradient(to bottom, rgb(235, 174, 114), rgb(231, 145, 65), rgb(248, 136, 9) ); */
  background-color: rgb(241, 239, 153);
  height: 700px;
  overflow: hidden;
}
.navbar-nav {
  padding-left: 90px;
}
.nav-item {
  margin-left: 20px;
  font-size: 18px;
  color: aliceblue !important;
  text-align: center !important;
}
.btn0 {
  background: rgb(80, 68, 56) !important;
  margin-right: 11px;
}
.nv1 {
  margin-left: 100px;
}
.img1 {
  width: 100%;
  height: 400px;
  margin-top: 100px;
}
.header-text {
  text-align: center;
  margin-top: 130px;
}
.sub-header-txt {
  color: rgb(1, 1, 90);
}
.h1-txt {
  background-color: rgb(221, 70, 0);
  color: white;
  border-radius: 11px;
}
nav {
  background: rgb(230, 112, 16);
}
.new-head{
  color: rgb(0, 0, 0);
  margin-top: 35px;
}
.new-head2{
  color: rgb(0, 0, 0);
  margin-top: 35px;
}
.btn1 {
  margin-right: 14px;
  background-color: rgb(221, 82, 18) !important;
  color: white !important;
}
.btn1-1 {
  margin-right: 15px;
  border: rgb(209, 70, 5) 1px solid !important;
}
.btn1-1:hover {
  background-color: rgb(209, 70, 5);
  color: white !important;
}
/* styling the about */
.cont2 {
  margin-top: 100px;
}
.about-text {
  margin-top: 50px;
}
/* animation for floating img */
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.img2 {
  transform: translateY(0px);
  animation: float 3.5s ease-in-out infinite;
}
.img2 img {
  width: 500px;
  height: 450px;
  padding-top: 50px;
}
.img5 {
  margin-left: -100px;
  width: 200px;
  height: 200px;
}

/* advertise section*/
.advertise{
  background-color: rgb(235, 243, 241);
}
.cont-adv{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px;
}
.adv-img2{
  border-radius: 14px;
  height: 450px;
  width: 750px;
}

/*categories section */
.cont3 {
  margin-top: 130px;
  margin-bottom: 70px;
}
.circle {
  margin-top: 70px;
  background-color: rgb(255, 255, 255);
  height: 200px;
  border: 1px solid rgb(156, 141, 141);
  box-shadow: 2px 2px 5px rgb(146, 141, 141);
  border-radius: 15px;
}
.circle:hover {
  background-color: rgb(182, 82, 1);
  color: rgb(253, 247, 247);
}
.img4 {
  width: 90px;
  height: 90px;
}
.cat-text {
  text-align: center;
  padding-top: 20px;
  font-size: 18px;
}
.cat-img {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Bannar Section Starts Here */
.bannar-section{
    margin-top: 120px;
    height: 470px;
    background:  linear-gradient(90deg, rgba(0, 0, 0, 0.54) 50%, rgba(0, 0, 0, 0.54) 50%), url(./images/unnamed.webp);
    background-size: cover;
    background-attachment: fixed;
    background-position: center -140px;
    position: relative;
    margin-bottom: 150px;
}
.bannar-section .bannar-caption{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.bannar-section h3{
    font-weight: 530;
    color:  rgb(209, 204, 201);
    font-size: 35px;
    margin-bottom: 25px;
}
.bannar-section p{ 
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-weight: 700;
    color: rgb(253, 5, 5);
    background-color: rgb(223, 219, 211);
    width: 400px;
    border-radius: 12px;
    font-size: 25px;
    padding: 5px;
}
.bannar-section p span{
    color: rgb(224, 19, 19);
}

@media(max-width: 574px){
    .bannar-section{
    background-position: center -40px;
}
.bannar-section h2{
    font-size: 40px;
}
.bannar-section p.caption{
    font-size: 17px;
}
}
@media(max-width: 462px){
   .bannar-section h2{
    font-size: 30px;
}
}

/*contact us section*/
.cont4 {
  margin-top: 140px;
}
.contact-img {
  width: 35px;
  height: 35px;
  margin-left: 5px;
}
.contact-txt {
  text-decoration: none;
  color: black !important;
  font-weight: 600;
  font-size: 17px;
}
.wrapper {
margin-left: 70px;
margin-right: 80px;
margin-top: 25px;
}
.img3 {
  width: 100%;
  height: 635px;
  margin-left: 0px;
  /* border-radius: 15px; */
}
.img3-bg {
  background-color: rgb(216, 99, 4);
  /* background: rgb(241, 239, 153); */
}
.img3-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-info {
  margin-top: 50px;
}
.form {
  margin-left: 9px;
}
.btn2 {
  width: 100%;
  margin-top: 10px;
  background-color: rgb(209, 70, 5) !important;
  color: white !important;
  margin-bottom: 15px !important;
}
.adv-img{
  width: 100%;
  height: 450px;
  border-radius: 13px;
}
/*login page */
.section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(
    -30deg,
    rgb(230, 112, 16) 50%,
    rgb(241, 239, 153) 50%
  );
}
.alert {
  display: flex;
  align-items: center;
  justify-content: center;
}
.errMsg {
  padding: 7px;
  background-color: brown;
  color: white;
  font-size: 16px;
  text-align: center;
}
.label {
  color: black;
  font-size: 16px;
  font-weight: 550;
}

.box {
  position: relative;
  width: 500px;
  height: fit-content;
  padding: 50px;
  background: rgba(255, 255, 255, 0.1);
  border: rgb(255, 255, 255, 0.1) solid 2px;
  box-shadow: 0 10px 35px black;
}
.btn3 {
  width: 120px;
  background-color: rgb(221, 82, 18) !important;
  color: white !important;
}
.btn7 {
  background-color: rgb(39, 39, 122) !important;
  color: white !important;
}

/*subjects starts here */

.order-all {
  padding: 10px;
  border: solid 1px rgb(0, 0, 0, 0.3);
  border-radius: 10px;
}
.order-all:hover {
  background-color: rgb(221, 82, 18) !important;
  color: white !important;
}
.card {
  box-shadow: 2px 2px 10px lightgray;
  margin-bottom: 30px;
  width: 21rem;
}
.card1 {
  width: 18rem;
}
.modal {
  color: black !important;
}
.sub-H {
  text-decoration: none;
  font-size: 20px;
  color: rgb(7, 7, 59);
}
.sub-H1 {
  margin-top: 70px;
}
.sub-H2 {
  margin-top: 100px;
}
.sub-H3 {
  margin-top: 100px;
}
.sub-form {
  margin-top: 250px;
  margin-right: 35px;
  padding-bottom: 30px;
  padding-top: 10px;
  padding-right: 7px;
  border-radius: 3px;
  background-color: rgb(235, 243, 241);
}

.cont9 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.cont6 {
  background-color: rgb(241, 239, 153);
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detail-text {
  margin-top: 100px;
}
.detail-img {
  margin-top: 100px;
}
.cont7 {
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 80px;
  text-align: center;
}
.btn8 {
  background-color: rgb(221, 82, 18) !important;
  color: white !important;
}
.btn8:hover {
  background-color: rgb(112, 45, 0) !important;
}

.cont10 {
  margin-top: 10px;
  display: flex;
  justify-content: center !important;
  padding-right: 20px;
}
.delete-btn {
  margin-right: 15px;
}
.video-body {
  background-color: rgb(121, 117, 115);
  color: white;
}
.order2 {
  margin-right: 40px !important;
}
.order-all-btn {
  margin-bottom: 50px !important;
}
.books{
  background-color: rgb(235, 243, 241);
  padding: 17px;
  border-radius: 8px;
}
/*admin dashboard */
.header {
  background-color: rgb(241, 239, 153);
  height: 300px;
}
.D-header {
  padding-top: 20px;
}
.D-img {
  width: 150px;
  height: 150px;
  animation: rotate 14s infinite linear;
}
.D-body {
  padding-top: 80px;
  margin-bottom: 80px;
  padding-bottom: 60px;
  padding-right: 20px !important;
  background-color: rgb(233, 227, 227);
}
div .row .D-body {
  overflow: hidden !important;
}
.D-btn {
  margin-top: 25px;
  width: 100%;
  height: 43px;
  font-size: large;
}
.all-btns {
  margin-bottom: 10px;
}
.all-1 {
  margin-left: 15px;
}
/*animate image */
@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.list-group-item {
  background-color: rgb(143, 192, 98);
  color: black;
  height: fit-content;
  font-size: larger;
  border-radius: 12px;
  margin-left: 14px;
  margin-top: 5px;
  margin-bottom: 9px;
}
.D-input {
  border: solid 1px rgb(121, 116, 116);
}
.U-header {
  padding-top: 50px;
}
.u-body {
  margin-top: 90px;
}
.del2 {
  margin-right: 30px !important;
}

/* animation to hide message */
.back-message {
  position: fixed;
  left: 0;
  top: 50%;
  z-index: 10;
  width: 100%;
  padding: 40px;
  color: rgb(36, 33, 33);
  border-radius: 20px;
}
.back-message-anim {
  animation: message-fade 7s ease-in-out forwards;
}
@keyframes message-fade {
  from {
    visibility: visible;
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.result {
  background-color: rgb(241, 239, 153);
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.h1-result {
  max-width: 500px;
  top: 50% !important;
  text-align: center;
}
.re-btn {
  margin-right: 17px;
}
.err-btn {
  margin: 20px;
}
.navigate {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-right: 0;
}

/*spinner css */
.spinner-cont {
  width: 100%;
  height: 150px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(240, 230, 230);
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner-loader {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.modal-btn1{
  margin-left: 33px;
}
/*footer*/
footer {
  text-align: center;
  padding-top: 30px;
  height: 80px;
  color: rgb(226, 222, 222);
  font-size: 18px;
  background-color: rgb(54, 52, 52);
  margin-bottom: 0;
}

/*responsivness */

@media only screen and (max-width: 532px) {
  .box {
    width: 420px;
  }
  .img1 {
    width: 270px !important;
  }
  .contact {
    width: 470px !important;
  }
  .adv-img{
    width: 470px;
  }
  .img3 {
    height: 370px;
  }
  .contact-info {
    margin-left: 25px;
  }
  .form {
    margin-right: 15px;
  }
  .nav-item {
    margin-right: 1px !important;
  }
  .btn2 {
    width: 100%;
    margin-bottom: 13px;
  }
  .p1 {
    margin-left: 15px;
  }
  .card {
    margin-right: 30px;
  }
  .p2 {
    margin-left: 40px;
  }
  .detail-text {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 1998px) {
  .btn2 {
    width: 100%;
  }
}

@media only screen and (max-width: 1026px) {
  .adv-img {
    width: 910px;
  }
  .wrapper{
    margin-right: 40px;
  }
}

@media only screen and (max-width: 1170px) {
  .img2 img {
    width: 440px;
    height: 300px;
  }
}

@media only screen and (max-width: 1050px) {
  .img2 img {
    width: 400px;
    height: 500px;
  }
}

@media only screen and (max-width: 990px) {
  .cont1 {
    height: 600px;
  }
  .btn2 {
    width: 100%;
  }
  .nav-item {
    margin-left: 1px;
  }
  .nv1 {
    margin-left: 1px;
  }
  .contact {
    width: 800px !important;
  }
  .adv-img{
    width: 800px;
    margin-right: 40px;
  }
}
@media (max-width: 925px){
  .adv-img{
    margin-right: 5px;
  }
}
@media (max-width: 850px) {
  .adv-img {
    width: 700px !important;
  margin-right: 10px;
  }
}

@media only screen and (max-width: 766px) {
  .cont1 {
    height: 550px;
  }
  .sub-form {
    margin-top: 70px;
  }
  .sub-H1 {
    margin-top: 40px;
  }
  .sub-H2 {
    margin-top: 40px;
  }
  .sub-H3 {
    margin-top: 40px;
  }
  .cont9 video {
    max-width: 500px !important;
    height: 430px !important;
  }
  .adv-img {
    width: 580px !important;
  }
  .adv-img2{
    width: 550px;
    height: 350px;
  }
  .header-text {
    margin-top: 50px;
  }
  .btn1 {
    height: 40px;
  }
  .header-text,
  h1 {
    font-size: 33px;
  }
  .btn2 {
    width: 100%;
    margin-bottom: 20px;
  }
  .contact-info {
    margin-left: 20px;
  }
  .form {
    margin-left: 12px;
  }
  .img3 {
    height: 440px;
  }
  .img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img1 {
    height: 130px;
    width: 350px;
    margin-top: 20px;
  }
  .r1 {
    align-items: center;
  }
  .card {
    justify-content: center !important;
    width: 310px;
    margin-right: 30px;
  }
  .img2 img {
    width: 440px;
    height: 400px;
  }
}

@media (max-width : 650px){
.adv-img{
  width: 500px !important;
  margin-right: 5px !important;
  margin-left: 10px !important;
}
}

@media only screen and (max-width: 580px) {
  .cont9 video {
    max-width: 420px !important;
    height: 400px !important;
  }
  .adv-img{
    width: 450px !important;
    margin-right: 3px !important;
  }
  .adv-img2{
    width: 400px;
  }
}

@media only screen and (max-width: 480px) {
  .card {
    width: 300px;
  }
  .cont9 video {
    max-width: 350px !important;
    height: 300px !important;
  }
  .img2 img {
    width: 300px !important;
    height: 280px;
  }
  .adv-img{
    width: 390px !important;
  }
  .wrapper{
    margin-right: 15px !important;
  }
  .btn2 {
    width: 100%;
  }
  .btn11{
    margin-top: 10px;
  }
  .box {
    width: 350px;
  }
}

@media only screen and (max-width: 410px) {
  .card {
    width: 250px;
    margin-right: 20px;
  }
  .adv-img{
    width: 330px !important;
    height: 400px !important;
  }
  .adv-img2{
    width: 340px;
  }
}

@media only screen and (max-width: 334px) {
  .adv-img{
    width: 280px !important;
  }
  .adv-img2{
    width: 290px;
  }
  .btn2 {
    width: 100%;
  }
  .cont9 video {
    max-width: 260px !important;
    height: 210px !important;
  }
  .card {
    margin-right: 10px;
  }
}

#zmmtg-root{
  display: none ;
}