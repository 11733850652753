.react-select {
  width: 250px !important;
  border: 1px solid rgb(68, 67, 67) !important;
  border-radius: 7px !important;
  direction: rtl !important;
  background-color: aliceblue;
}
.inside-select {
  color: black !important;
}
@media only screen and (max-width: 990px) {
  .react-select {
    width: 700px !important;
    margin-right: 60px !important;
  }
}
@media only screen and (max-width: 813px) {
  .react-select {
    width: 600px !important;
    margin-right: 20px !important;
  }
}
@media only screen and (max-width: 710px) {
  .react-select {
    width: 500px !important;
    margin-right: 10px !important;
  }
}
@media only screen and (max-width: 605px) {
  .react-select {
    width: 400px !important;
    margin-right: 10px !important;
  }
}
@media only screen and (max-width: 500px) {
  .react-select {
    width: 300px !important;
    margin-right: 10px !important;
  }
}
@media only screen and (max-width: 400px) {
  .react-select {
    width: 260px !important;
    margin-right: 5px !important;
  }
}
@media only screen and (max-width: 300px) {
  .react-select {
    width: 150px !important;
    margin-right: 10px !important;
  }
}
