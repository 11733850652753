.Zoom {
  background-color: lightgray;
}
.Zoom h4{
  padding-top: 135px;
}
.JoinZoom2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 50px;
}
.del-zoom {
  margin-right: 15px;
}
.JoinZoom {
  display: flex;
  justify-content: center;
  align-items: center;
}
.JZ-content {
  width: 600px;
  background-color: rgb(235, 243, 241);
  text-align: center;
  height: 120px;
  border-radius: 12px;
}
.JZ-content h4 {
  padding-top: 14px;
}

.zoom-form {
  width: 600px;
  background-color: rgb(235, 243, 241);
  text-align: center;
  height: 170px;
  border-radius: 12px;
}
.zoom-form h4 {
  padding-top: 14px;
}
.zoom-form input {
  margin: 12px;
  padding: 6px;
  border-radius: 4px;
  border: initial 1px lightgray;
  width: fit-content;
}

@media only screen and (max-width: 620px) {
  .JZ-content {
    width: 350px;
  }
}
@media only screen and (max-width: 580px) {
  .zoom-form {
    height: 225px;
  }
}
@media only screen and (max-width: 400px) {
  .JZ-content {
    width: 250px;
  }
  .zoom-form {
    height: 255px;
  }
}

#zmmtg-root{
  display: none ;
}