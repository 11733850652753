.subject-pdf {
  max-width: 100% !important;
}
.pdf-body {
  margin-top: 80px;
  margin-bottom: 70px;
}
.pdf-a {
  text-decoration: none;
  color: white;
}
.custom-card {
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 2px 2px 10px lightgray;
  margin-bottom: 50px;
}
.cust-H {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 10px;
}
.cust-B {
  background-color: grey;
  color: white;
  padding-right: 20px;
  padding-bottom: 15px;
}
.cust-B button {
  margin-top: 15px;
  padding-right: 9px;
}
.pdf-D-card {
  background-color: rgb(241, 241, 241);
}
.headerPdf {
  height: 240px;
  background-color: rgb(241, 239, 153);
}
.del-pdf {
  margin-right: 9px;
}
/*style and responsive pdf */
.pdf-cont {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}
.pdf {
  width: 800px;
  height: 600px;
}

@media only screen and (max-width: 860px) {
  .pdf {
    width: 600px;
    height: 500px;
  }
}

@media only screen and (max-width: 650px) {
  .pdf {
    width: 400px;
    height: 400px;
  }
}

@media only screen and (max-width: 456px) {
  .pdf {
    width: 330px;
    height: 300px;
  }
}
